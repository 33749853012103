@import "../../reskin/reskin.css";

.navbar_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: #323e43;
  display: flex;
  align-items: center;
  z-index: 99;
}

.sticky {
  transform: translateY(-100px) !important;
  transition: 0.5s;
}

.navbar {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.fa-bars {
  display: none;
}

.navbar::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(
    to right,
    var(--navBarMain),
    var(--navLightGradient)
  );
}

.navbar_holder {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 200px 1fr 200px;
  align-items: center;
  position: relative;
}

.nav_logo_holder {
  width: 170px;
}

.nav_logo {
  width: 100%;
}

.navbar_items {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(6, 180px);
  justify-items: center;
}

/* .navbar_items.free-version {
  grid-template-columns: repeat(2, 180px);
} */

.navbar_item_active span {
  background: var(--navBarMain) !important;
}

.navbar_item span .news_icon {
  width: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navbar_item {
  position: relative;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  cursor: pointer;
}

.navbar_item span {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #656e72;
  font-size: 24px;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.navbar_item:hover > span {
  background: #a4afb3;
}

.navbar_item span img {
  width: 27px;
}

.navbar_item_active .tooltip {
  display: block !important;
}

.login-register {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  justify-items: center;
}

/* .login-register a {
  text-decoration: none;
  color: #fff;
  letter-spacing: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 45px;
}

.login-register a:hover > span {
  background: #a4afb3;
}

.login-register span {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #656e72;
  font-size: 24px;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
  position: relative;
} */

.tooltip {
  /* display:none; */
  position: absolute;
  width: 320px;
  background: white;
  z-index: 1001;
  top: 110%;
  /* left: -80% !important; */
  border-radius: 5px;
  transition: 0.5s;
  box-shadow: 0px 0px 5px 2px #5f5f5f87;
  cursor: default;
  pointer-events: none;
  height: auto;
}

.tooltip p {
  color: black;
  padding: 10px 20px;
  text-align: center;
  width: 100%;
  font-size: 16px !important;
  text-transform: none !important;
  letter-spacing: 0;
  height: auto;
  padding-bottom: 50px !important;
  white-space: unset !important;
  margin: 0 !important;
}

.tooltip.second {
  left: -55% !important;
  top: 140%;
}
.tooltip.third {
  left: -1650% !important;
  top: 200%;
  width: 330px;
  /* height: auto; */
}
.tooltip.third p {
  height: auto;
  padding-bottom: 50px;
}
.tooltip.third .triangle {
  left: 90%;
}
.tooltip.fourth {
  left: 0 !important;
  width: 350px;
}
.tooltip.fifth {
  left: 10px !important;
  padding-top: 10px;
  top: 120px;
  width: 330px;
  /* height: 90px; */
}
.tooltip.fifth p {
  border-bottom: none !important;
}
.tooltip.six {
  top: 78%;
  left: 5% !important;
}
.tooltip.six-second {
  top: 79%;
  left: 5% !important;
}
.tooltip.six-third {
  top: 17%;
  left: 5% !important;
}
.tooltip.seven {
  top: 150%;
  left: -196% !important;
}
.tooltip.eight {
  top: 150%;
  left: -255% !important;
}
.tooltip.nine {
  left: 41% !important;
  top: 33%;
}
.tooltip.ten {
  top: 70%;
  left: 41% !important;
}
.tooltip.eleven {
  top: 108%;
  left: 41% !important;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  left: 0;
  top: 0;
  cursor: default;
  transition: 0.5s;
  /* pointer-events: none; */
}

.tooltip .triangle {
  position: absolute;
  bottom: 100%;
  left: calc(50% - 10px);
  width: 1px;
  height: 2px;
  /* background:yellow; */
  border: 10px solid transparent;
  border-bottom: 10px solid white;
  pointer-events: none;
}

.tooltip button {
  position: absolute !important;
  height: auto !important;
  width: 140px !important;
  right: 10px;
  bottom: 10px;
  background: white !important;
  padding: 5px 0;
  color: var(--navBarMain) !important;
  border: 2px solid var(--navBarMain) !important;
  border-radius: 5px;
  font-size: 15px !important;
  font-weight: bold;
  pointer-events: all;
  margin: 0 !important;
}

.tooltip button.skip {
  position: absolute;
  left: 10px !important;
  bottom: 10px !important;
  color: rgb(224, 46, 46) !important;
  border-radius: 5px;
  border-color: transparent !important;
  border-bottom-color: rgb(224, 46, 46) !important;
}

.search {
  justify-self: end;
  display: flex;
  align-items: center;
}

.search .help {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: 20px;
  background: #656e72;
  color: white;
}

.search .help svg {
  margin-left: 9px;
  margin-top: 8px;
  font-size: 23px;
  cursor: pointer;
}

.search .help .tooltip {
  position: absolute;
  width: 300px !important;
  background: white;
  height: 90px;
  z-index: 1000;
  top: 140% !important;
  left: -650% !important;
  border-radius: 5px;
  transition: 0.5s;
  box-shadow: 2px 2px 5px 0px #5f5f5f87;
}

.search .help .tooltip .triangle {
  position: absolute;
  bottom: 100%;
  left: 90%;
  width: 1px;
  height: 2px;
  /* background:yellow; */
  border: 10px solid transparent;
  border-bottom: 10px solid white;
}

.sticky .search .help .tooltip {
  opacity: 0;
  pointer-events: none;
}

.search .help .tooltip p {
  text-align: left;
}

.search .help .tooltip button {
  position: absolute;
  bottom: 5px;
  left: 85%;
  background: none;
  color: var(--navBarMain);
  border: none !important;
  font-size: 18px;
  font-weight: bold;
  width: auto !important;
  right: auto !important;
}
.search_icon {
  color: #fff;
  font-size: 34px;
  margin-top: 5px;
  cursor: pointer;
}

.search_holder {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 250px;
}

.search_holder input {
  border: 0;
  border-bottom: 1px solid #fff;
  background: transparent;
  font-size: 16px;
  padding: 5px 0;
  width: 100%;
  animation: width 0.6s forwards;
  transition: all 0.3s ease-in-out;
  color: #fff;
  margin-left: 7px;
}

@keyframes width {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.search_holder .fa-times {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

@media (max-width: 1600px) {
  .navbar_items {
    grid-template-columns: repeat(6, 130px);
  }
}

@media (max-width: 1400px) {
  .navbar_holder {
    grid-template-columns: 1fr 750px 1fr;
  }

  .nav_logo_holder {
    width: 120px;
  }

  .navbar_items {
    grid-template-columns: repeat(6, 130px);
  }
}

@media (max-width: 1130px) {
  .navbar_holder {
    grid-template-columns: 1fr 500px 1fr;
  }

  .navbar_items {
    grid-template-columns: repeat(6, 100px);
  }
}

@media (max-width: 1150px) {
  .search_holder {
    width: 200px;
  }

  .search_icon {
    font-size: 24px;
  }

  .search_holder input {
    font-size: 14px;
  }

  .search_holder .fa-times {
    font-size: 16px;
  }

  .navbar_item span {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-bottom: 7px;
  }

  .navbar_item {
    font-size: 12px;
  }

  .navbar_item span img {
    width: 22px;
  }
}

@media (max-width: 1000px) {
  .navbar_holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .search {
    display: none;
  }

  .search_holder.hidden {
    display: flex;
    position: fixed;
    left: 20px;
    bottom: 20px;
    z-index: 9999;
    width: 250px;
  }

  .fa-bars {
    display: inline;
    position: absolute;
    right: 0;
    color: #fff;
    font-size: 26px;
    cursor: pointer;
  }

  .navbar_items {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 300px;
    background: #323e43;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 9999;
    box-shadow: 0 0 20px 0 #000;
    overflow: hidden;
    padding: 20px 0;
    transition: all 0.3s ease-in-out;
    transform: translateX(-350px);
  }

  .active_navbar_items {
    transform: translateX(0) !important;
  }

  .navbar_item {
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;
    font-size: 18px;
    padding: 10px 20px;
  }

  .navbar_item > span {
    margin: 0 10px 0 0 !important;
  }

  .navbar_item_active {
    background: var(--navBarMain) !important;
  }

  .navbar_item_active span {
    background: #323e43 !important;
  }
}

@media (max-width: 500px) {
  .navbar_items {
    width: 250px;
  }

  .navbar_item {
    font-size: 14px;
  }

  .navbar_item > span {
    width: 35px;
    height: 35px;
    font-size: 16px;
  }

  .search_holder.hidden {
    width: 200px;
  }
}
