@import "../../reskin/reskin.css";

.video_player_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: #000;
}

.video_player_wrapper .ad {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 150px; */
  z-index: 20;
}

/* .video_player_wrapper.demo::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 180px;
  background-image: url("../../images/ad.png");
  background-position: center;
  pointer-events: none;
} */

.video_player_wrapper > div:nth-child(1) {
  width: 100% !important;
  height: 100% !important;
  position: relative;
}

.video_player_wrapper video {
  object-fit: cover !important;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.player_loader {
  position: fixed !important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 9999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.player_loader svg {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-animation: rotate 1s linear infinite;
  animation: rotate 1s linear infinite;
  font-size: 4.2em;
  color: var(--main);
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.second-player {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
}

.second-player .video_player_icons {
  position: absolute;
  bottom: -30% !important;
  top: auto !important;
  transition: 0.5s;
}

.second-player:hover .video_player_icons {
  bottom: 0 !important;
}

.second-player > div:nth-child(1) {
  width: 100% !important;
  height: 100% !important;
  position: relative;
}

.close-player {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  /* background: url(../../images/player_icons/closeWhite.png); */
  background-size: 50% 50%;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 999;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  transform: translate3d(150px, 0, 0);
}

.close-player svg {
  color: #fff;
  transition: all 0.5s ease-in-out;
}

.close-player:hover {
  /* background: url(../../images/player_icons/closeBlue.png); */
  background-size: 50% 50%;
  background-position: center center;
  background-repeat: no-repeat;
}

.close-player:hover svg {
  color: var(--main);
}

.video_player_bottom_side {
  position: fixed;
  left: 205px;
  right: 0px;
  bottom: 0;
  transition: all 0.5s ease-in-out;
  transform: translate3d(0, 500px, 0);
  background: linear-gradient(180deg, transparent 0, #000);
  padding: 0 40px 10px 40px;
  z-index: 10;
}

.top_part {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top_part .video_player_info {
  display: flex;
  align-items: center;
  color: #fefefd;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
  font-size: 28px;
  letter-spacing: 0.25px;
}

.top_part .video_player_info_img {
  width: 138px;
  height: 76px;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  margin-right: 20px;
  position: relative;
}

.top_part .video_player_info_img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  max-height: 60%;
}

.video_player_icons {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video_player_icons img {
  width: 60px;
  height: 60px;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.75));
  margin: 0 10px;
  cursor: pointer;
  opacity: 0.8;
  transition: all 0.5s ease;
}

.video_player_icons span {
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.75));
  width: 35px;
  height: 35px;
  color: #fff;
  opacity: 0.8;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video_player_icons span svg {
  font-size: 18px;
}

.video_player_icons img:hover {
  opacity: 1;
}

.video_player_controls_right {
  display: flex;
  align-items: center;
}

.video_player_controls_right .emiter {
  position: absolute;
  display: flex;
  align-items: center;
  margin-right: 20px;
  text-align: left;
  font-size: 20px;
  bottom: 60px;
  right: -38px;
}

.video_player_controls_right .emiter p {
  font-size: 20px;
}

.video_player_controls_right .emiter .InternetTv {
  height: 25px;
}

.video_player_controls_right .emiter img {
  height: 25px;
  margin: 0 15px;
}

.video_player_controls_right p {
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.75);
  color: #fefefd;
  font-size: 16px;
  letter-spacing: 0.25px;
}

.video_player_controls_right.none p {
  display: none;
}

.video_player_controls_right .volume_holder {
  width: 30px;
  height: 30px;
  margin: 0 15px;
  position: relative;
}

.video_player_controls_right .volume_holder img {
  width: 30px;
  height: 30px;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.75));
  opacity: 0.8;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.video_player_controls_right .volume_holder img:hover {
  opacity: 1;
}

.video_player_controls_right .fullscreen_icon {
  width: 25px;
  height: 25px;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.75));
  opacity: 0.8;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.video_player_controls_right .enable_pip {
  font-size: 32px;
  line-height: 5px;
  box-sizing: border-box;
  color: white;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.75));
  opacity: 0.8;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.video_player_controls_right .enable_pip img {
  /* margin:0 20px; */
  width: 30px;
  margin-right: 20px;
  margin-top: 2px;
}

.video_player_controls_right .fullscreen_icon:hover {
  opacity: 1;
}

.volume_holder:hover .range_holder {
  opacity: 1 !important;
}

.range_holder {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 4px;
  width: 100px;
  transform: rotate(-90deg);
  position: absolute;
  left: -40px;
  bottom: 80px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.range_wrap {
  width: 100%;
  height: 4px;
  background: #d3d3d3;
  outline: none;
  border-radius: 2px;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
}

.video_player_controls_right input {
  cursor: pointer;
  position: absolute;
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  background: #d3d3d3;
  outline: none;
  border-radius: 2px;
}

.video_player_controls_right input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background: #fff;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
  cursor: pointer;
  border-radius: 50%;
}

.video_player_controls_right input::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #fff;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
}

.video_player_bottom_side .progress {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.video_player_bottom_side .progress.none {
  margin-bottom: 20px;
}

.video_player_bottom_side .progress input {
  background: var(--main);
  width: 100%;
  height: 3px;
  -webkit-appearance: none;
  cursor: pointer;
}

.video_player_bottom_side .progress input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background: #fff;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
  cursor: pointer;
  border-radius: 50%;
}

.video_player_bottom_side .progress input::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #fff;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
}

.video_player_mouse_move {
  position: fixed;
  top: 0;
  right: 0;
  left: 220px;
  bottom: 260px;
}

/* ANIMATION */
.video_player_wrapper:hover .left_slider {
  transform: translate3d(0, 0, 0);
}

.video_player_wrapper:hover .video_player_bottom_side {
  transform: translate3d(0, 0, 0);
}

.video_player_wrapper:hover .close-player {
  transform: translate3d(0, 0, 0);
}

.video_player_wrapper.fadeout .left_slider {
  transform: translate3d(-250px, 0, 0) !important;
}

.video_player_wrapper.fadeout .video_player_bottom_side {
  transform: translate3d(0, 500px, 0) !important;
}

.video_player_wrapper.fadeout .close-player {
  transform: translate3d(150px, 0, 0) !important;
}
