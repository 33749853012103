@import "../../../reskin/reskin.css";

.internetTVTrack_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-bottom: 1px solid #ccc;
  z-index: 1;
  background: #eeee;
  position: relative;
}

.internetTVTrack_wrapper a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  box-shadow: 0 0 20px 5px rgba(126, 124, 124, 0.1);
  border: 0;
  width: 150px;
  height: 30px;
  border-radius: 4px;
  color: #222;
  font-size: 15px;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  background: #fff;
  text-align: center;
  margin-top: 4px;
  position: relative;
}

.internetTVTrack_wrapper a:hover {
  background: linear-gradient(var(--main), var(--secondaryGradient)) !important;
  color: #fff;
}

@media (max-width: 800px) {
  .internetTVTrack_wrapper a {
    margin: 0 5px;
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .internetTVTrack_wrapper a {
    width: 100px;
    font-size: 10px;
  }
}
