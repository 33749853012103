@import "../../../../reskin/reskin.css";

.epglists_wrapper {
  width: calc(100% - 480px);
  height: 100%;
  position: relative;
}

.epglists {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  margin: 0px 30px 0px 0;
  padding: 0px 5px 50px 80px;
}

/* width */
.epglists::-webkit-scrollbar {
  width: 7px;
  border-radius: 30px;
}

/* Track */
.epglists::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.epglists::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 30px;
}

/* Handle on hover */
.epglists::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.epgload {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  color: #222;
}

.epglists_item_date {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin: 30px 0 10px 0;
  font-size: 18px;
  font-weight: 800;
  color: var(--main);
  border: 1px solid #e5e5e5;
}

.epglists_item {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}

.epglists_item span {
  color: #a7a7a7;
  border: 1px solid #a7a7a7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}

.epglists_item span .play {
  margin-left: 2px;
}

.epglists_item span:hover {
  border: 1px solid var(--main);
  color: var(--main);
}

.epglists_item .proggres_bar {
  height: 2px;
  bottom: 0;
}

.epglists_item .proggres_bar .buffer {
  height: 2px;
}

.epglists_item_row {
  display: flex;
  align-items: center;
  color: #000;
}

.epglists_item_row h5 {
  font-size: 22px;
  font-weight: lighter;
  margin-right: 10px;
}

.epglists_item_row h2 {
  font-size: 18px;
  font-weight: lighter;
}

@media (max-width: 1500px) {
  .epglists_wrapper {
    width: calc(100% - 450px);
  }

  .epglists {
    padding-left: 50px;
    padding-bottom: 20px;
  }

  .epglists_wrapper .marker-top-epgs {
    left: 30px;
  }

  .epglists_wrapper .marker-bottom-epgs {
    left: 30px;
  }
}

@media (max-width: 1250px) {
  .epglists_wrapper {
    width: calc(100% - 400px);
  }
}

@media (max-width: 950px) {
  .epglists_wrapper {
    width: calc(100% - 350px);
  }

  .epglists_item_row h5 {
    font-size: 18px;
  }

  .epglists_item_row h2 {
    font-size: 14px;
  }
}

@media (max-width: 900px) {
  .epglists_wrapper {
    width: 100%;
    padding-bottom: 20px;
  }

  .epglists {
    padding: 0px;
    margin-right: 5px;
    padding: 0 50px;
  }
}

.epglists_item .marker-center-epgs {
  position: absolute;
  left: -22px;
  width: 44px;
  height: 34px;
  bottom: -16px;
  z-index: 50;
  cursor: pointer;
}

.marker-top-epgs {
  position: absolute;
  top: 5px;
  left: 60px;
  width: 44px;
  height: 34px;
  z-index: 50;
  transform: rotate(-90deg);
  cursor: pointer;
  display: none;
}

.marker-bottom-epgs {
  position: absolute;
  bottom: 5px;
  left: 60px;
  width: 44px;
  height: 34px;
  z-index: 50;
  transform: rotate(90deg);
  cursor: pointer;
  display: none;
}
