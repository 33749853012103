@import "../../../reskin/reskin.css";

.internet_channels {
  min-height: calc(100vh - 175px);
}

.internet_channels_holder {
  height: calc(100vh - 235px);
}

.isEmpty {
  width: calc(100% - 450px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #777;
}

.isEmpty svg {
  font-size: 60px;
}

.isEmpty p {
  font-size: 28px;
}

.no_added_internet_channels {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000;
}

.no_added_internet_channels svg {
  font-size: 70px;
  color: rgb(156, 156, 156);
  margin-bottom: 10px;
}

.no_added_internet_channels p {
  padding: 0 20px;
  font-size: 28px;
}

.no_added_internet_channels a {
  background: linear-gradient(var(--main), var(--secondaryGradient));
  color: #fff;
  border: none;
  padding: 5px 25px;
  margin-top: 10px;
  border-radius: 5px;
}

@media (max-width: 1500px) {
  .isEmpty {
    font-size: 26px;
  }

  .nochannel_wrap {
    font-size: 26px !important;
  }

  .isEmpty {
    width: calc(100% - 400px);
  }

  .no_added_internet_channels p {
    text-align: center;
  }
}

@media (max-width: 1150px) {
  .isEmpty {
    width: calc(100% - 350px);
  }
}

@media (max-width: 900px) {
  .isEmpty {
    width: 100%;
  }

  .no_added_internet_channels svg {
    font-size: 60px;
  }

  .no_added_internet_channels p {
    font-size: 24px;
  }

  .no_added_internet_channels span {
    font-size: 20px;
  }
}

.internet_channels .show_more {
  width: 100%;
  height: 50px;
  cursor: pointer;
  margin: 20px 0;
  border: 0;
  border-radius: 8px;
  background: linear-gradient(var(--main), var(--secondaryGradient));
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
}

.internet_channels .show_more:hover {
  opacity: 0.9;
}
